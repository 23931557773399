<template>
    <div class="w-full min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div class="max-w-max mx-auto space-y-default">
            <div class="mx-auto">
                <img class="h-8 p-1 w-auto" :src="require('@/assets/app-logo-light.svg')">
            </div>
            <main class="sm:flex">
                <p class="text-4xl font-extrabold text-gray-100 sm:text-5xl">
                    {{ $route.params.code }}
                </p>

                <div class="sm:ml-6">
                    <div class="sm:border-l sm:border-default sm:pl-6">
                        <h1 class="text-4xl font-extrabold text-gray-200 tracking-tight sm:text-5xl">
                            {{ code.title }}
                        </h1>

                        <p class="mt-1 text-base text-gray-400">
                            {{ code.message }}
                        </p>
                    </div>

                    <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6 justify-center sm:justify-start">
                        <template v-if="$me.getUser()">
                            <v-button :to="{ name: 'panel.dashboard' }" color="primary" class="w-full sm:w-auto">
                                Dashboard
                            </v-button>
                        </template>

                        <template v-else>
                            <v-button :to="{ path: '/' }" color="primary" class="w-full sm:w-auto">
                                Home
                            </v-button>
                        </template>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            codes: {
                401: {
                    title: "Unauthenticated",
                    message: "You need to be logged in to view this page.",
                },
                403: {
                    title: "Unauthorized",
                    message: "You do not have the necessary permissions to view this page.",
                },
                404: {
                    title: "Not Found",
                    message: "Please check the URL in the address bar and try again.",
                },
                500: {
                    title: "Server Error",
                    message: "Something went wrong, we have been notified of this error.",
                },
            },
        };
    },
    computed: {
        code() {
            return this.codes[this.$route.params.code];
        },
    },
};
</script>
